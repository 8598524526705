import { useCallback, useEffect, useState } from "react";
import RestApi from "utils/RestApi";
import { TestNotificationExecuter } from "app/process/pushNotification/edit/testNotificationExecuter";
import {
  STEP_FUNCTIONS_STATUS_RESPONSE,
  StepFunctionsStatusResponse,
} from "app/system_defaults/step_functions_status";
import {
  GrantMessage,
  GrantMessageType,
  GrantMessageV2,
} from "types/grant_message";
import TestSettingUrlGenerator from "app/process/pushNotification/edit/testSettingUrlGenerator";
import useGrantMessageSetting from "./useGrantMessageSetting";
import useGrantMessageV2 from "../useGrantMessageV2";
import DomainRepository from "utils/repositories/DomainRepository";
import { Domain } from "types/domain";

const STATUS_CHECK_INTERVAL_MS = 5000;

export type TestNotificationParameters = {
  isRunning: boolean;
  status: StepFunctionsStatusResponse | null;
  subscribeUrl: string | null;
  testUserRegisterUrl: string | null;
  grantMessageType: GrantMessageType | undefined;
};

export type TestNotificationCallbacks = {
  execute: (campaignUuid: string) => void;
};

const useTestNotification = (
  managementApi: RestApi
): [TestNotificationParameters, TestNotificationCallbacks] => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [subscribeUrl, setSubscribeUrl] = useState<string | null>(null);
  const [testUserRegisterUrl, setTestUserRegisterUrl] = useState<string | null>(
    null
  );

  const [status, setStatus] = useState<StepFunctionsStatusResponse | null>(
    null
  );

  const grantMessageSetting = useGrantMessageSetting(managementApi);

  const [grantMessages] = useGrantMessageV2(managementApi);

  const [selectedGrantMessage, setSelectedGrantMessage] = useState<
    GrantMessageV2 | undefined
  >(undefined);

  const executeTestNotification = useCallback(
    async (campaignUuid: string) => {
      const executer = new TestNotificationExecuter(managementApi);

      setIsRunning(true);

      const requestUuid = await executer.request(campaignUuid);

      const id = setInterval(async () => {
        setStatus(await executer.getStatus(requestUuid));
      }, STATUS_CHECK_INTERVAL_MS);

      setIntervalId(id);
    },
    [managementApi, setIsRunning]
  );

  const loadSubscriptions = useCallback(
    async (grantMessage: GrantMessage | undefined) => {
      const response = await new DomainRepository(managementApi).get();

      if (response.items.length > 0) {
        const generator = new TestSettingUrlGenerator();

        const subscriptionUrlSettings: Domain[] = response.items.filter(
          (item) => item.is_subscribe
        );

        const originUrl = new URL(
          "",
          `https://${subscriptionUrlSettings[0].domain}`
        );

        setSubscribeUrl(
          grantMessage
            ? generator.getSubscribeUrl(
                originUrl,
                grantMessage.grantMessage.action_uuid
              )
            : originUrl.toString()
        );

        setTestUserRegisterUrl(generator.generateTestRegisterUrl(originUrl));
      }
    },
    [managementApi]
  );

  useEffect(() => {
    if (status !== null && status !== STEP_FUNCTIONS_STATUS_RESPONSE.RUNNING) {
      setIsRunning(false);
      setStatus(null);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [status, intervalId, setIsRunning]);

  useEffect(() => {
    if (grantMessageSetting && grantMessages) {
      const grantMessage = grantMessages.find(
        (gm) =>
          gm.grantMessage.grant_message_type ===
          grantMessageSetting.selected_message_type
      );
      setSelectedGrantMessage(grantMessage);
    }
  }, [grantMessageSetting, grantMessages, setSelectedGrantMessage]);

  useEffect(() => {
    loadSubscriptions(selectedGrantMessage);
  }, [selectedGrantMessage, loadSubscriptions]);

  return [
    {
      isRunning: isRunning,
      status: status,
      subscribeUrl: subscribeUrl,
      testUserRegisterUrl: testUserRegisterUrl,
      grantMessageType: grantMessageSetting?.selected_message_type,
    },
    {
      execute: executeTestNotification,
    },
  ];
};

export default useTestNotification;
